.slide_right:hover {
  box-shadow: inset 400px 0 0 0 #4ade80;
}

.button_slide {
  border: 2px solid #4ade80;
  box-shadow: inset 0 0 0 0 #4ade80;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.heroSection {
  position: relative;
}

.heroSection::after {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 320px) {
  .gallery-style {
    background-repeat: repeat-x;
    background-size: contain;
  }
}
